import { is_defined } from './utils/is_defined';

/**
 * Override default settings with any available instance-specific settings
 * @param {Object} platform_settings Default settings (can be overridden by settings in the `settings` object)
 * @param {Object} settings_from_config The settings for this instance as loaded from the config file
 */
export function process_settings(platform_settings, settings_from_config) {
  /**
   * Initialize instance_settings with defaults
   */
  var instance_settings = platform_settings.default;

  /**
   * If settings_from_config is not defined, there is nothing to override - just
   * return the default settings.
   */
  if(!is_defined(settings_from_config)) {
    return instance_settings;
  }

  /**
   * Merge settings from configuration file into instance settings
   * @x-technical-debt: this should be done through plain object merging, after
   * validating settings_from_config against our settings schema (which needs
   * to be defined)
   */
  if(is_defined(settings_from_config.title)) {
    instance_settings.title = settings_from_config.title;
  }
  if(is_defined(settings_from_config.subtitle)) {
    instance_settings.subtitle = settings_from_config.subtitle;
  }
  if(is_defined(settings_from_config.about)) {
    instance_settings.subtitle = settings_from_config.about;
  }
  if(is_defined(settings_from_config.colour)) {
    instance_settings.subtitle = settings_from_config.colour;
  }
  if(is_defined(settings_from_config.map)) {
    if(is_defined(settings_from_config.map.root_selector)) {
      instance_settings.map.root_selector = settings_from_config.map.root_selector
    }
    if(is_defined(settings_from_config.map.markers)) {
      instance_settings.map.markers = settings_from_config.map.markers
    }
    if(is_defined(settings_from_config.map.zoom)) {
      instance_settings.map.zoom = settings_from_config.map.zoom
    }
    if(is_defined(settings_from_config.map.zoom)) {
      instance_settings.map.disableClusteringAtZoom = settings_from_config.map.disableClusteringAtZoom
    }
  }

  return instance_settings;
}
