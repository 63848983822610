import { is_defined } from './utils/is_defined.js';

export function count_rows_with_data(dataset, item) {
  return dataset.reduce(function(pv, cv, ci, a) {
    return (is_defined(cv[item]) && cv[item] !== "") ? pv + 1 : pv;
  }, 0);
}

export function replace_undefined_values (parameters, variable) {
  if (!is_defined(parameters.row[variable]) || parameters.row[variable] === "") {
    parameters.dataset[parameters.index][variable] = parameters.empty_value;
  }
}

export function is_column_not_empty(dataset, item) {
  return count_rows_with_data(dataset, item) > 0;
}

export function list_all_pattrn_variables(variables) {
  var variable_list = [];

  Object.keys(variables).forEach(function(variable_type) {
    variable_list = variable_list.concat(variables[variable_type]);
  });

  return variable_list;
}
