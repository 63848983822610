/**
 * check if variable is defined
 * @param variable The variable to check
 * @return bool Whether the variable is defined
 */
export function is_defined(variable) {
  if (typeof variable !== 'undefined' && variable !== null) {
    return true;
  } else {
    return false;
  }
};
