// require dependencies
var jquery = require('jquery');

// expose jquery as global.jQuery; this is needed for jQuery plugins
global.jQuery = jquery;

// require bootstrap (menus etc) - needs jQuery global
require('bootstrap');

// Leaflet:
// main library (for global L)
require('leaflet');
// and leaflet.marketcluster plugin
require('leaflet.markercluster');

// lightgallery jQuery plugin
require('lightgallery');

// Lastly, import the main pattrn() function
// TECHNICAL_DEBT: this will be broken down in manageable modules
import { pattrn }  from './platform.js';

/**
 * finally, invoke the actual monolithic pattrn() function
 * TECHNICAL_DEBT: until the full pattrn() function is refactored, we pass in
 * its dependencies, in order to shim the legacy Pattrn code
 */
pattrn();
